<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
          <b-overlay :show="loading">
            <b-row>
                <b-col lg="4" sm="12">
                    <CCard>
                        <CCardBody>
                            <b-row>
                                <b-col>
                                    <div class='text-center'>
                                        <img class="pro-img" width='80px' :src="formData.picture ? formData.picture : baseUrl + `assets/imgs/avatar.svg`">
                                        <h5 class="mt-2 m-0">{{ formData.name }}</h5>
                                        <p class="text-primary p-0 m-0 mb-2">{{ formData.email }}</p>
                                    </div>
                                    <div>
                                        <table class='table table-sm table-bordered'>
                                            <tr>
                                                <th>Point</th>
                                                <td>: {{ formData.point }}</td>
                                            </tr>
                                            <tr>
                                                <th>Paid</th>
                                                <td>: {{ 0 }}</td>
                                            </tr>
                                            <tr>
                                                <th>Member Since</th>
                                                <td>: {{ formData.created_at | dateFormat }}</td>
                                            </tr>
                                            <tr>    
                                                <th>Status</th>
                                                <td> : 
                                                    <span v-if="parseInt(formData.status) === 1" class="badge badge-success">Active</span>
                                                    <span v-else class="badge badge-danger">Block</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="text-center">
                                        <button v-if="parseInt(formData.status) === 2" class="btn btn-sm btn-success mt-1" @click="changeStatus(formData)" style="width:70%"><i class="ri-check-line"></i> Active</button>
                                        <button v-else class="btn btn-sm btn-danger mt-1" @click="changeStatus(formData)" style="width:70%"><i class="ri-close-line"></i> Block</button>
                                    </div>
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
                <b-col lg="8" sm="12">
                    <CCard>
                        <CCardBody>
                            <div>
                                <b-tabs content-class="mt-3">
                                    <b-tab title="Work Summary" active>
                                        <table class="table table-sm table-bordered">
                                            <tr>
                                                <th>Title</th>
                                                <th>Total Count</th>
                                                <th>Cost/Income</th>
                                            </tr>
                                            <tr>
                                                <th>Task Income</th>
                                                <td> {{ report.work_count }}</td>
                                                <td> {{ report.total_work_point }}</td>
                                            </tr>
                                            <tr>
                                                <th>Refer Income</th>
                                                <td> {{ report.total_refer_count }}</td>
                                                <td> {{ report.total_refer_point }}</td>
                                            </tr>
                                            <tr>
                                                <th>Admin Point</th>
                                                <td> {{ report.total_admin_point_count }}</td>
                                                <td> <span class="text-left">{{ report.total_admin_point }}</span>
                                                    <button class="btn btn-sm btn-primary ml-5" v-b-modal.add-point>Add</button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Withdraw Cost</th>
                                                <td> {{ report.withdraw_count }}</td>
                                                <td> {{ report.total_withdraw_point }}</td>
                                            </tr>
                                            <tr>
                                                <th>Balance</th>
                                                <td> {{ report.balane }}</td>
                                                <td> {{ report.total_withdraw_point }}</td>
                                            </tr>
                                        </table>
                                        <table class="table table-sm table-bordered">
                                            <tr>
                                                <th>Title</th>
                                                <th>Real Balance</th>
                                                <th>User Balance</th>
                                                <th>Status</th>
                                            </tr>
                                            <tr>
                                                <td> Balance</td>
                                                <td> {{ report.balane }}</td>
                                                <td> {{ formData.point }}</td>
                                                <td>
                                                    <span v-if="(parseFloat(report.balane)  == parseFloat(formData.point)) && parseInt(formData.point) >= 0" class="badge badge-success">Valid User</span>
                                                    <span v-else class="badge badge-danger">Invalid User</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </b-tab>
                                    <b-tab title="Info">
                                        <table class="table table-sm table-bordered">
                                            <tr>
                                                <td width="30%">User ID</td>
                                                <td> : {{ formData.id }}</td>
                                            </tr>
                                            <tr>
                                                <td>Name</td>
                                                <td> : {{ formData.name }}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td> : {{ formData.email }}</td>
                                            </tr>
                                            <tr>
                                                <td>Mobile</td>
                                                <td> : {{ formData.mobile }}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Amount</td>
                                                <td> : {{ formData.point }}</td>
                                            </tr>
                                            <tr>
                                                <td>Account IP</td>
                                                <td> : {{ formData.account_ip }}</td>
                                            </tr>
                                            <tr>
                                                <td>Register Date</td>
                                                <td> : {{ formData.created_at | dateFormat }}</td>
                                            </tr>
                                        </table>
                                    </b-tab>
                                    <b-tab title="Change Info">
                                        <UserInfoChange :id="$route.query.id" />
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
          </b-overlay>
      </b-col>
      <b-col lg="6" sm="6" class="mb-2">
          <button @click="setBtn('widthdraw', 'Withdraw')" :class="(btn === 'widthdraw') ? `btn btn-success ml-2` : `btn btn-secondary ml-2`">Widthdraw List</button>
          <button @click="setBtn('refer', 'ReferUser')" :class="(btn === 'refer') ? `btn btn-success ml-2` : `btn btn-secondary ml-2`">Refer List</button>
          <button @click="setBtn('task', 'WorkList')" :class="(btn === 'task') ? `btn btn-success ml-2` : `btn btn-secondary ml-2`">Work List</button>
      </b-col>
      <b-col v-if="formData.id" lg="12" sm="12">
          <component :is="comp" :user_id="formData.id" :own_refer_id="formData.user_id" ></component>
      </b-col>
    </b-row>
    <b-modal id="add-point"
    size="lg"
	header-bg-variant="primary"
	header-text-variant="light"
    title="Add Point" hide-footer>
    <div>
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">   
		<b-form  @submit.prevent="handleSubmit(submitAddPoint)" @reset.prevent="reset" >
            <div class="row">
                <div class="col-md-6">
                    <ValidationProvider name="Point" vid="point" rules="required">
                        <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="pointForm"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        Point <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                            id="point"
                            v-model="pointForm.point"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-md-6">
                    <ValidationProvider name="Remarks" vid="remarks">
                        <b-form-group
                        class="row"
                        label-cols-sm="12"
                        label-for="remarksForm"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        Remarks
                        </template>
                        <b-form-input
                            id="remarks"
                            v-model="pointForm.remarks"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-md-12 text-right">
                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Save</b-button>
                </div>
            </div>
        </b-form>
        </ValidationObserver>
    </div>
  </b-modal>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import iziToast from 'izitoast';
import Notification from './Notification'
import UserInfoChange from './UserInfoChange'
import Withdraw from './../../components/user/withdraw'
import ReferUser from './../../components/user/refer-user'
import WorkList from './../../components/user/task-complete'

export default {
  props: ['id'],
  components: {
    Notification,
    UserInfoChange,
    Withdraw,
    ReferUser,
    WorkList
    },
  created () {
	if (this.$route.query.id) {
        this.getItem()
        this.getWorkStatus()
    }
  },
  data () {
    return {
      baseUrl: baseUrl,
      saveBtnName: this.$route.query.id ? 'Update' : 'Save',
      formData: {
		type_id: 0,
        name: ''
      },
      editId: '',
      comp: 'Withdraw',
      btn: 'widthdraw',
      loading: false,
      report: {},
      pointForm: {
        point: null
      }
    }
  },
  computed: {
  },
  methods: {
    setBtn (btnName, CompName) {
        this.btn = btnName
        this.comp = CompName
    },
    edit (item) {
        this.editId = item.id
    },
    submitAddPoint () {
        this.loading = true
        RestApi.putData(baseUrl, `api/user-signup/add-point/${this.$route.query.id}`, { user_id: this.$route.query.id, point: this.pointForm.point, remarks: this.pointForm.remarks }).then(response => {
            if (response.success) {
                iziToast.success({
                    title: 'Success!',
                    message: response.message
                })
                this.getWorkStatus()
            }
            this.loading = false
            this.$bvModal.hide('add-point')
        })
    },
	getWorkStatus () {
        this.loading = true
        RestApi.getData(baseUrl, `api/user-signup/work-status/${this.$route.query.id}`).then(response => {
            if (response.success) {
                this.report = response.data
            }
            this.loading = false
        })
	},
	getItem () {
        this.loading = true
         RestApi.getData(baseUrl, `api/user-signup/show/${this.$route.query.id}`).then(response => {
                if (response.success) {
                    this.formData = response.data
                }
                this.loading = false
            })
	},
    changeStatus (item) {
        this.$swal({
            title: 'Are you sure to change status ?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
            this.toggleStatus(item)
            }
        })
    },
    toggleStatus (item) {
        RestApi.deleteData(baseUrl, `api/user-signup/user-status/${item.id}`).then(response => {
            if (response.success) {
                this.getItem()
                iziToast.success({
                    title: 'Success!',
                    message: response.message
                })
            } else {
                iziToast.error({
                    title: 'Error!',
                    message: response.message
                })
            }
        })
    },
  }
}
</script>
<style scoped>
.pro-img {
    border: 3px solid gray;
    border-radius: 70px;
    width: 90px;
    height: 90px;
}
</style>
